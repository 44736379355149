import { Link, NavbarMenu, NavbarMenuItem } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../utils/firebase";
import { signOut } from 'firebase/auth';

/**
 * SidePanel component that renders the navigation menu for the application.
 * It provides navigation to different parts of the application and handles user logout.
 *
 * @param {Object} props - Component props.
 * @param {string} props.userType - The type of the user (e.g., 'organization_admin').
 * @param {Object} props.selectedOrganization - The currently selected organization with an _id property.
 */
export default function SidePanel({ userType, selectedOrganization }) {
    const navigate = useNavigate();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isProfileSubMenuOpen, setIsProfileSubMenuOpen] = useState(false);
    const [isSmsSubMenuOpen, setIsSmsSubMenuOpen] = useState(false);
    const [isEmailSubMenuOpen, setIsEmailSubMenuOpen] = useState(false);
    const [isAssetSubMenuOpen, setIsAssetSubMenuOpen] = useState(false);

    /**
     * Resets submenu states when the selected organization changes.
     */
    useEffect(() => {
        setIsSubMenuOpen(false);
        setIsProfileSubMenuOpen(false);
        setIsSmsSubMenuOpen(false);
        setIsEmailSubMenuOpen(false);
        setIsAssetSubMenuOpen(false);
    }, [selectedOrganization]);

    /**
     * Handles the user logout process by signing out and redirecting to the login page.
     */
    const handleLogout = () => {
        console.log('Logging out...');
        signOut(auth).then(() => {
            navigate('/login');
        }).catch((error) => {
            console.error("Logout error:", error);
        });
    };

    /**
     * Navigates to the Dashboard of the selected organization.
     */
    const handleDashboard = () => {
        navigate(`/orgs/${selectedOrganization._id}/dashboard`);
    };

    /**
     * Toggles the visibility of the organization settings submenu.
     */
    const handleOrganizationSettings = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    /**
     * Toggles the visibility of the profile settings submenu.
     */
    const handleProfileSettings = () => {
        setIsProfileSubMenuOpen(!isProfileSubMenuOpen);
    };

    /**
     * Toggles the visibility of the sms submenu.
     */
    const handleSmsSettings = () => {
        setIsSmsSubMenuOpen(!isSmsSubMenuOpen);
    };

    /**
     * Toggles the visibility of the email submenu.
     */
    const handleEmailSettings = () => {
        setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
    };

    /**
     * Toggles the visibility of the asset submenu.
     * @param {boolean} value - The new state of the submenu.
     * @returns {void}
     */
    const handleAssetSettings = (value) => {
        setIsAssetSubMenuOpen(value);
    }

    return (
        <NavbarMenu>
            <NavbarMenuItem>
                <Link color="foreground" style={{ cursor: 'pointer' }} onClick={handleDashboard}>
                    Dashboard
                </Link>
            </NavbarMenuItem>
            <NavbarMenuItem style={{ cursor: 'pointer' }}>
                <Link onClick={handleEmailSettings} color="foreground">
                    Email
                </Link>
            </NavbarMenuItem>
            {isEmailSubMenuOpen && (
                <>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/templates`);
                            }}
                        >
                            Templates
                        </Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/recipients`);
                            }}
                        >
                            Recipients
                        </Link>
                    </NavbarMenuItem>
                    {/* <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/groups`);
                            }}
                        >
                            Groups
                        </Link>
                    </NavbarMenuItem> */}
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/campaigns`);
                            }}
                        >
                            Campaigns
                        </Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/email-addresses`);
                            }}
                        >
                            Email Addresses
                        </Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/domains`);
                            }}
                        >
                            Domains
                        </Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/inbox`);
                            }}
                        >
                            Inbox
                        </Link>
                    </NavbarMenuItem>
                    {/* <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsEmailSubMenuOpen(!isEmailSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/email/automations`);
                            }}
                        >
                            Automations
                        </Link>
                    </NavbarMenuItem> */}
                </>
            )}
            {/* <NavbarMenuItem style={{ cursor: 'pointer' }}>
                <Link onClick={handleAssetSettings} color="foreground">
                    Assets
                </Link>
            </NavbarMenuItem> */}
            {/* {isAssetSubMenuOpen && (
                <>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsAssetSubMenuOpen(!isAssetSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/assets/landing-pages`);
                            }}
                        >
                            Landing Pages
                        </Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsAssetSubMenuOpen(!isAssetSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/assets/digital-tools`);
                            }}
                        >
                            Digital Tools
                        </Link>
                    </NavbarMenuItem>
                </>
            )} */}
            {userType === 'organization_admin' && (
                <NavbarMenuItem style={{ cursor: 'pointer' }}>
                    <Link onClick={handleOrganizationSettings} color="foreground">
                        Organization Settings
                    </Link>
                </NavbarMenuItem>
            )}
            {isSubMenuOpen && (
                <>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsSubMenuOpen(!isSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/organization-settings/team`);
                            }}
                        >
                            Team
                        </Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsSubMenuOpen(!isSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/organization-settings/billing`);
                            }}
                        >
                            Billing
                        </Link>
                    </NavbarMenuItem>
                </>
            )}
            {/* <NavbarMenuItem style={{ cursor: 'pointer' }}>
                <Link onClick={handleProfileSettings} color="foreground">
                    Profile Settings
                </Link>
            </NavbarMenuItem> */}
            {isProfileSubMenuOpen && (
                <>
                    {/* <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsProfileSubMenuOpen(!isProfileSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/profile-settings/basic-info`);
                            }}
                        >
                            Basic Info
                        </Link>
                    </NavbarMenuItem> */}
                    <NavbarMenuItem style={{ paddingLeft: '1.5rem', cursor: 'pointer' }}>
                        <Link
                            color="foreground"
                            className="w-full"
                            onClick={() => {
                                setIsProfileSubMenuOpen(!isProfileSubMenuOpen);
                                navigate(`/orgs/${selectedOrganization._id}/profile-settings/invites`);
                            }}
                        >
                            Invites
                        </Link>
                    </NavbarMenuItem>
                </>
            )}
            <NavbarMenuItem>
                <Link color="danger" style={{ cursor: 'pointer' }} onClick={handleLogout}>
                    Log Out
                </Link>
            </NavbarMenuItem>
        </NavbarMenu>
    );
}