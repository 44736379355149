const API_URL = process.env.REACT_APP_API_URL;

/**
 * Handles the response from a fetch call.
 * 
 * @param {Response} response The response object from fetch.
 * @returns {Promise<Object>} A promise that resolves to the JSON body.
 * @throws {Error} If the response status is not OK.
 */
async function handleResponse(response) {
    if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
    }
    return response.json();
}

/**
 * Fetches user details by email.
 * 
 * @param {string} email The email of the user to retrieve.
 * @returns {Promise<Object>} A promise that resolves to the user's details.
 * @throws {Error} If fetching user by email fails.
 */
async function getUserByEmail(email) {
    try {
        const response = await fetch(`${API_URL}/users/email/${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Fetching user by email failed:', error);
        throw error;
    }
}

/**
 * Creates an organization administrator.
 * 
 * @param {Object} createOrgAdminData The data to create an organization admin.
 * @returns {Promise<Object>} A promise that resolves to the creation result.
 * @throws {Error} If creating organization admin fails.
 */
async function createOrgAdmin(createOrgAdminData) {
    try {
        const response = await fetch(`${API_URL}/org-admin/create-org-admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(createOrgAdminData)
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Creating organization admin failed:', error);
        throw error;
    }
}


/**
 * Fetches all organizations affiliated with a user by their email address.
 * 
 * @param {string} email The email address of the user whose organizations are to be retrieved.
 * @returns {Promise<Array>} A promise that resolves to a list of organizations affiliated with the user.
 * @throws {Error} If fetching organizations fails.
 */
async function getUserOrganizations(email) {
    try {
        const response = await fetch(`${API_URL}/users/organizations/${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Fetching user organizations failed:', error);
        throw error;
    }
}

/**
 * Fetches a permission object for a user within a specific organization.
 * 
 * @param {string} userId The _id of the user.
 * @param {string} organizationId The _id of the organization.
 * @returns {Promise<Object>} A promise that resolves to the permission object found.
 * @throws {Error} If fetching permission fails.
 */
async function getUserOrganizationPermission(userId, organizationId) {
    try {
        const response = await fetch(`${API_URL}/users/permissions/${encodeURIComponent(userId)}/${encodeURIComponent(organizationId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Fetching user organization permission failed:', error);
        throw error;
    }
}

/**
 * Invites a new user to the platform.
 * 
 * @param {Object} inviteData The data required to invite a user.
 * @returns {Promise<Object>} A promise that resolves to the result of the invitation process.
 * @throws {Error} If inviting the user fails.
 */
async function inviteUser(inviteData) {
    try {
        const response = await fetch(`${API_URL}/users/invite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(inviteData)
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Inviting user failed:', error);
        throw error;
    }
}

/**
 * Fetches all invitation permissions for a user.
 * 
 * @param {string} userId The unique identifier of the user whose invitation permissions are to be retrieved.
 * @returns {Promise<Array>} A promise that resolves to a list of invitation permissions for the user.
 * @throws {Error} If fetching invitation permissions fails.
 */
async function getUserInvitations(userId) {
    try {
        const response = await fetch(`${API_URL}/users/invitations/${encodeURIComponent(userId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Fetching user invitations failed:', error);
        throw error;
    }
}

/**
 * Accepts an invitation for a user within a specific organization.
 * 
 * @param {string} userId The unique identifier of the user.
 * @param {string} organizationId The unique identifier of the organization.
 * @returns {Promise<Object>} A promise that resolves to the result of the acceptance process.
 * @throws {Error} If accepting the invitation fails.
 */
async function acceptInvitation(userId, organizationId) {
    try {
        const response = await fetch(`${API_URL}/users/permissions/accept-invitation/${encodeURIComponent(userId)}/${encodeURIComponent(organizationId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Accepting invitation failed:', error);
        throw error;
    }
}

/**
 * Fetches user details by user ID.
 * 
 * @param {string} userId The ID of the user to retrieve.
 * @returns {Promise<Object>} A promise that resolves to the user's details.
 * @throws {Error} If fetching user by ID fails.
 */
async function getUserById(userId) {
    try {
        const response = await fetch(`${API_URL}/users/id/${encodeURIComponent(userId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Fetching user by ID failed:', error);
        throw error;
    }
}

// Export the new function along with the existing ones
/**
 * Removes a user from an organization and deletes the corresponding permission entry.
 * 
 * @param {string} email The email address of the user.
 * @param {string} organizationId The _id of the organization to remove the user from.
 * @returns {Promise<Object>} A promise that resolves to the result of the removal process.
 * @throws {Error} If removing the user from the organization fails.
 */
async function removeUserFromOrganization(email, organizationId) {
    try {
        const response = await fetch(`${API_URL}/users/organizations/${encodeURIComponent(email)}/${encodeURIComponent(organizationId)}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Removing user from organization failed:', error);
        throw error;
    }
}

// Export the new function along with the existing ones
export { getUserByEmail, getUserById, createOrgAdmin, getUserOrganizations, getUserOrganizationPermission, inviteUser, getUserInvitations, acceptInvitation, removeUserFromOrganization };