import React from 'react';
import Editor from '@monaco-editor/react';

const MonacoEditorComponent = ({ value, onChange }) => {
    return (
        <Editor
            height="500px"
            defaultLanguage="html"
            value={value}
            onChange={(newValue) => onChange(newValue)}
            theme="vs-dark"
        />
    );
};

export default MonacoEditorComponent;