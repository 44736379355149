import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyEmailAddress } from '../../services/emailAddressService';
import { Spinner } from '@nextui-org/react';

export default function EmailValidation() {
    const { organization_id, email_address } = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        verifyEmailAddress(organization_id, email_address)
            .then(() => {
                navigate(`/orgs/${organization_id}/email/email-addresses`);
            })
            .catch(err => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [organization_id, email_address, navigate]);

    return (
        <div>
            {loading && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <p>Verifying your email address<span style={{ display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>...</span></p>
                    <Spinner size='large' color='primary' />
                </div>
            )}
            {error && <p>Error: {error}</p>}
        </div>
    );
}