import { Button, Modal, ModalBody, ModalContent, ModalHeader, Spinner, ModalFooter, Textarea } from "@nextui-org/react";
import { useEffect, useRef, useState } from "react";
import { CodeCopyBtn } from "./CodeCopyBtn";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { ReferenceDropdown } from "./ReferenceDropdown";
import './ChatModal.css';
import { fetchSiteHtml } from '../services/aiService'; // Import the service

export const ChatModal = ({ isOpen, onClose, messages, input, setInput, handleSubmit, isChatLoading, code }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [references, setReferences] = useState([]);
    const [context, setContext] = useState('');
    const [fetchedUrls, setFetchedUrls] = useState(new Set());
    const inputRef = useRef(null);

    useEffect(() => {
        setReferences([{ label: 'Code in Editor', value: code }]);
    }, [code]);

    const handleInputChange = async (e) => {
        const value = e.target.value;
        setInput(value);

        if (value.endsWith('@')) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }

        // Check for URL references
        const urlMatch = value.match(/@https?:\/\/[^\s]+/);
        if (urlMatch) {
            const url = urlMatch[0].substring(1); // Remove '@'
            if (!fetchedUrls.has(url)) {
                try {
                    const siteContent = await fetchSiteHtml(url);
                    setContext((prevContext) => prevContext + `\n\`\`\`html\n${siteContent.content}\n\`\`\``);
                    setFetchedUrls((prevUrls) => new Set(prevUrls).add(url));
                } catch (error) {
                    console.error('Error fetching site content:', error);
                }
            }
        }

        // Remove context if reference is removed
        const referenceLabels = references.map(ref => ref.label);
        const removedReferences = referenceLabels.filter(label => !value.includes(label));
        if (removedReferences.length > 0) {
            setContext((prevContext) => {
                let newContext = prevContext;
                removedReferences.forEach(label => {
                    const regex = new RegExp(`\n\`\`\`javascript\n${references.find(ref => ref.label === label).value}\n\`\`\``, 'g');
                    newContext = newContext.replace(regex, '');
                });
                return newContext;
            });
        }
    };

    const handleSelectReference = (ref) => {
        const newInputValue = input + `${ref.label} `;
        setInput(newInputValue);
        setContext((prevContext) => prevContext + `\n\`\`\`javascript\n${ref.value}\n\`\`\``); // Add code to context
        setShowDropdown(false);
        inputRef.current.focus();
    };

    const renderInputWithReferences = () => {
        const parts = input.split(/(@\w+)/g);
        return parts.map((part, index) => {
            if (part.startsWith('@')) {
                return <span key={index} className="selected-reference">{part}</span>;
            }
            return part;
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} placement="top-center" size="xl">
            <ModalContent
                style={{
                    minHeight: '500px',
                    minWidth: '500px',
                }}
            >
                <ModalHeader>
                    <h3>Chat with AI</h3>
                </ModalHeader>
                <ModalBody>
                    <div style={{ maxHeight: '400px', overflowY: 'auto', marginBottom: '1rem' }} className="chat-modal">
                        {messages.map((msg, index) => (
                            <div key={index} style={{ marginBottom: '1rem' }}>
                                <strong>{msg.role === 'user' ? 'You' : 'AI'}:</strong>
                                <ReactMarkdown
                                    rehypePlugins={[rehypeRaw]}
                                    remarkPlugins={[remarkGfm]}
                                    components={{
                                        pre: ({ children }) => (
                                            <div style={{ position: 'relative' }}>
                                                <CodeCopyBtn>{children}</CodeCopyBtn>
                                                <pre className="blog-pre">{children}</pre>
                                            </div>
                                        ),
                                        code({ node, inline, className = "blog-code", children, ...props }) {
                                            const match = /language-(\w+)/.exec(className || '');
                                            return !inline && match ? (
                                                <div>
                                                    <CodeCopyBtn>{children}</CodeCopyBtn>
                                                    <SyntaxHighlighter
                                                        style={a11yDark}
                                                        language={match[1]}
                                                        PreTag="div"
                                                        {...props}
                                                    >
                                                        {String(children).replace(/\n$/, '')}
                                                    </SyntaxHighlighter>
                                                </div>
                                            ) : (
                                                <code className={className} {...props}>
                                                    {children}
                                                </code>
                                            );
                                        }
                                    }}
                                >
                                    {msg.content}
                                </ReactMarkdown>
                            </div>
                        ))}
                    </div>
                </ModalBody>
                <ModalFooter style={{ display: 'block' }}>
                    <form onSubmit={(e) => handleSubmit(e, context)} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        <Textarea
                            ref={inputRef}
                            value={input}
                            onChange={handleInputChange}
                            placeholder="Type your message..."
                            minRows={2}
                            maxRows={4}
                            style={{ marginRight: '1rem', flex: 1 }}
                        />
                        <Button type="submit" color="primary" disabled={isChatLoading}>
                            {isChatLoading ? <Spinner size='sm' color='white' /> : 'Send'}
                        </Button>
                        {showDropdown && <ReferenceDropdown references={references} onSelect={handleSelectReference} />}
                    </form>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};