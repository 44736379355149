import React from 'react';
import { List, ListItem, ListItemText, Paper } from '@mui/material';

export const ReferenceDropdown = ({ references, onSelect }) => {
    return (
        <Paper style={{ position: 'absolute', bottom: '60px', left: '0', width: '100%', zIndex: 10 }}>
            <List>
                {references.map((ref, index) => (
                    <ListItem button key={index} onClick={() => onSelect(ref)}>
                        <ListItemText primary={ref.label} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};