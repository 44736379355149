import { Button, Card, CardBody, CardHeader, Code, Input } from "@nextui-org/react";
import { useState } from "react";
import { CardTitle, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../contexts/RegistrationContext";
import { getUserByEmail } from "../../services/userService";


export default function StartPoint() {
    const [email, setEmail] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { registrationData, updateRegistrationData } = useRegistration();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // This line prevents the default form submission behavior
        setLoading(true);
        setError(''); // Clear any existing errors

        // Validation logic
        if (!email || !organizationName) {
            setError('Please fill in all fields.');
            setLoading(false);
            return;
        }

        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email.toLowerCase())) {
            setError('Please enter a valid email.');
            setLoading(false);
            return;
        }

        try {
            // Check if user already exists
            const existingUser = await getUserByEmail(email.toLowerCase());
            if (existingUser) {
                setError('A user with this email already exists.');
                setLoading(false);
                return;
            }

            // Here you would typically handle the actual registration logic
            // For now, we'll just update the registration context
            updateRegistrationData({
                email: email.toLowerCase(),
                organization_name: organizationName
            });

            // Navigate to the next page or show success message
            navigate('/register/user-details');
        } catch (error) {
            // If an error occurs while fetching the user, it could mean the user doesn't exist or there's a network issue
            if (error.message.includes('404')) {
                // User not found, proceed with registration
                updateRegistrationData({
                    email: email.toLowerCase(),
                    organization_name: organizationName
                });
                navigate('/register/user-details');
            } else {
                // Handle other errors, e.g., by setting an error state
                setError('An error occurred during registration.');
            }
        } finally {
            setLoading(false); // Reset loading state regardless of outcome
        }
    };

    return (
        <Card style={{ width: '90%', maxWidth: '500px', margin: '50px auto', padding: '10px' }}>
            <CardHeader>
                <CardTitle>Start Point</CardTitle>
            </CardHeader>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formOrganization">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Organization Name</Form.Label>
                        <Input
                            placeholder="Enter organization name"
                            type="text"
                            variant="bordered"
                            value={organizationName}
                            onChange={(e) => setOrganizationName(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Email</Form.Label>
                        <Input
                            placeholder="Enter email"
                            type="email"
                            variant="bordered"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Button
                        style={{ width: '100%', marginTop: '20px' }}
                        color="primary"
                        onClick={(e) => handleSubmit(e)} // Pass the event object to the handleSubmit function
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Next'}
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}