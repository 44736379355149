import { createContext, useState, useContext } from 'react';

const RegistrationContext = createContext();

export const useRegistration = () => useContext(RegistrationContext);

export const RegistrationProvider = ({ children }) => {
    const [registrationData, setRegistrationData] = useState({});

    const updateRegistrationData = (newData) => {
        setRegistrationData({ ...registrationData, ...newData });
    };

    return (
        <RegistrationContext.Provider value={{ registrationData, updateRegistrationData }}>
            {children}
        </RegistrationContext.Provider>
    );
};