import { Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";

export const PreviewModal = ({ isOpen, onClose, code }) => (
    <Modal isOpen={isOpen} onClose={onClose} placement="top-center" size="xl">
        <ModalContent>
            <ModalHeader>
                <h3>HTML Preview</h3>
            </ModalHeader>
            <ModalBody>
                <iframe
                    srcDoc={code}
                    style={{ width: '100%', height: '600px', border: 'none' }}
                />
            </ModalBody>
        </ModalContent>
    </Modal>
);