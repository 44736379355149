import { Button, Card, CardBody, CardHeader, Input } from "@nextui-org/react";
import { CardTitle, Col, Form, Row } from "react-bootstrap";
import { useRegistration } from "../../contexts/RegistrationContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createOrgAdmin } from "../../services/userService";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

export default function Overview() {
    const { registrationData, updateRegistrationData } = useRegistration();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        setError('');

        const createOrgAdminData = {
            ...registrationData,
        };

        try {
            const response = await createOrgAdmin(createOrgAdminData);
            console.log(response);

            // Initialize Firebase Auth
            const auth = getAuth();
            // Create user with email and password in Firebase
            await createUserWithEmailAndPassword(
                auth,
                registrationData.email,
                registrationData.password // Make sure you have password in your registrationData
            );

            navigate(`/orgs/${response.organization._id}/dashboard`);
        } catch (error) {
            setError('Registration failed: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card style={{ width: '90%', maxWidth: '500px', margin: '50px auto', padding: '10px' }}>
            <CardHeader>
                <CardTitle>Overview</CardTitle>
            </CardHeader>
            <CardBody>
                <Form>
                    <b>Organization Details</b>
                    <Form.Group controlId="formOrganizationName"
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                        <Input
                            placeholder="Enter organization name"
                            type="text"
                            variant="faded"
                            value={registrationData?.organization_name}
                            disabled={true}
                        />
                    </Form.Group>
                    <b>User Details</b>
                    <Form.Group controlId="formFirstName"
                        style={{ marginBottom: '2.5x', marginTop: '10px' }}
                    >
                        <Input
                            placeholder="Enter first name"
                            type="text"
                            variant="faded"
                            value={registrationData?.first_name}
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="formLastName"
                        style={{ marginBottom: '10px' }}
                    >
                        <Input
                            placeholder="Enter last name"
                            type="text"
                            variant="faded"
                            value={registrationData?.last_name}
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail"
                        style={{ marginBottom: '10px', marginTop: '10px' }}
                    >
                        <Input
                            placeholder="Enter email"
                            type="email"
                            variant="faded"
                            value={registrationData?.email}
                            disabled={true}
                        />
                    </Form.Group>
                    <b>Billing Address</b>
                    <Form.Group
                        controlId="country"
                        style={{ marginTop: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="faded"
                            placeholder="Country"
                            value={registrationData?.country}
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="formAddress1"
                        style={{ marginTop: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="faded"
                            placeholder="Address line 1"
                            value={registrationData?.address_line_1}
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="formAddress2"
                        style={{ marginTop: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="faded"
                            placeholder="Address line 2"
                            value={registrationData?.address_line_2}
                            disabled={true}
                        />
                    </Form.Group>
                    <Row
                        style={{ marginTop: '10px' }}
                    >
                        <Col>
                            <Form.Group controlId="formCity">
                                <Input
                                    type="text"
                                    variant="faded"
                                    placeholder="City"
                                    value={registrationData?.city}
                                    disabled={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formPostalCode">
                                <Input
                                    type="text"
                                    variant="faded"
                                    placeholder="Postal code"
                                    value={registrationData?.postal_code}
                                    disabled={true}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formStateOrRegion"
                        style={{ marginTop: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="faded"
                            placeholder="State or region"
                            value={registrationData?.state_or_region}
                            disabled={true}
                        />
                    </Form.Group>
                    <Button
                        style={{ width: '100%', marginTop: '20px' }}
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Register'}
                    </Button>
                </Form>
            </CardBody>
        </Card>
    );
}