import { Button, Card, CardBody, CardHeader, Code, Input } from "@nextui-org/react";
import { useState } from "react";
import { CardTitle, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../contexts/RegistrationContext";


export default function UserDetails() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { registrationData, updateRegistrationData } = useRegistration();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submit action
        setLoading(true);
        setError(''); // Clear any existing errors

        // Validation logic
        if (!firstName || !lastName || !password || !confirmPassword) {
            setError('Please fill in all fields.');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        try {
            // Here you would typically handle the actual registration logic
            // For now, we'll just update the registration context
            updateRegistrationData({
                first_name: firstName,
                last_name: lastName,
                password,
            });

            // Navigate to the next page or show success message
            navigate('/register/billing-details');
        } catch (error) {
            // Handle errors, e.g., by setting an error state
            setError('An error occurred during registration.');
        } finally {
            setLoading(false); // Reset loading state regardless of outcome
        }
    };

    return (
        <Card style={{ width: '90%', maxWidth: '500px', margin: '50px auto', padding: '10px' }}>
            <CardHeader>
                <CardTitle>User Details</CardTitle>
            </CardHeader>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formFirstName">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>First Name</Form.Label>
                        <Input
                            placeholder="Enter first name"
                            type="text"
                            variant="bordered"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formLastName">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Last Name</Form.Label>
                        <Input
                            placeholder="Enter last name"
                            type="text"
                            variant="bordered"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Email</Form.Label>
                        <Input
                            placeholder="Enter email"
                            type="email"
                            variant="faded"
                            value={registrationData?.email}
                            disabled={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Password</Form.Label>
                        <Input
                            placeholder="Enter password"
                            type="password"
                            variant="bordered"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Confirm Password</Form.Label>
                        <Input
                            placeholder="Confirm password"
                            type="password"
                            variant="bordered"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Button
                        style={{ width: '100%', marginTop: '20px' }}
                        color="primary"
                        onClick={handleSubmit} // Changed from onPress to onClick
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Next'}
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}