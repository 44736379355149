import { Button, Card, CardBody, CardHeader, Code, Input } from "@nextui-org/react";
import { useState } from "react";
import { CardTitle, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getUserByEmail } from "../services/userService";
import { auth } from "../utils/firebase";

export default function Login() {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // Call useNavigate to get the navigate function

    const handleSubmit = async () => {
        if (loading) return;

        setLoading(true);
        setError('');

        if (!password || !email) {
            setError('Email and password are required');
            setLoading(false);
            return;
        }

        try {
            // Use Firebase authentication to sign in
            await signInWithEmailAndPassword(auth, email.toLowerCase(), password);
            // If successful, redirect to the dashboard or home page
            const userInfo = await getUserByEmail(email);
            if (!userInfo) {
                setError('User information not found.');
                setLoading(false);
                return;
            }
            navigate(`/orgs/${userInfo.organizations[0]}/dashboard`); // Replace '/dashboard' with your success route
        } catch (error) {
            // Handle errors here, such as wrong password, no user, etc.
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card style={{ width: '90%', maxWidth: '500px', margin: '50px auto', padding: '10px' }}>
            <CardHeader>
                <CardTitle>Login</CardTitle>
            </CardHeader>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Email</Form.Label>
                        <Input
                            placeholder="Enter email"
                            type="email"
                            variant="bordered"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Password</Form.Label>
                        <Input
                            placeholder="Enter Password"
                            type="password"
                            variant="bordered"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Button
                        style={{ width: '100%', marginTop: '20px' }}
                        color="primary"
                        onPress={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Submit'}
                    </Button>
                </Form>
            </CardBody>
        </Card>
    );
}