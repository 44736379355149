// frontend/src/components/CreateOrganizationModal.js
import React, { useEffect, useState } from 'react';
import { Button, Code, Input, Modal, ModalBody, ModalContent, ModalHeader, Select, SelectItem, Spinner } from '@nextui-org/react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Col, Row } from 'react-bootstrap';
import { getData } from 'country-list';
import { createOrganization } from '../services/organizationService';
import { getCustomerPaymentMethods } from '../services/billingService';

/**
 * Modal component for creating an organization.
 * Provides a form for users to enter their organization details and payment information.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isOpen - Indicates if the modal is open.
 * @param {Function} props.onOpenChange - Handler for changing the open state of the modal.
 * @param {string} props.userEmail - The email of the user, to be pre-filled and disabled.
 */
const CreateOrganizationModal = ({ isOpen, onOpenChange, userEmail, billingInfo }) => {
    // State for form fields with default values
    const [form, setForm] = useState({
        organizationName: '',
        nameOnCard: '',
        country: 'US', // Default to United States
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: '',
        stateOrRegion: '',
    });
    const [loading, setLoading] = useState(false); // State to indicate loading status
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]); // Step 2: New state for payment methods

    useEffect(() => {
        // Step 3: Fetch payment methods
        const fetchPaymentMethods = async () => {
            try {
                const methods = await getCustomerPaymentMethods(billingInfo?.customer_id); // You need to provide the customer ID
                setPaymentMethods(methods);
            } catch (error) {
                setError('Failed to fetch payment methods');
            }
        };

        if (isOpen) {
            fetchPaymentMethods();
        }
    }, [isOpen]); // Fetch payment methods when the modal opens

    const renderPaymentMethodOptions = () => {
        return paymentMethods.map((method) => {
            // Assuming method.card contains the card details like in PaymentMethodsTab.js
            const brand = method.card.brand.charAt(0).toUpperCase() + method.card.brand.slice(1); // Capitalize the first letter
            const last4 = method.card.last4;
            const description = `${brand} •••• ${last4}`; // Construct the description string

            return (
                <SelectItem key={method.id} value={method.id}>{description}</SelectItem>
            );
        });
    };

    // Fetch country data for the select dropdown
    const countries = getData();

    // Define card element styles
    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    /**
     * Updates the form state when an input changes.
     * 
     * @param {string} field - The name of the form field to update.
     * @param {string} value - The new value for the form field.
     */
    const handleInputChange = (field, value) => {
        if (field === 'selectedPaymentMethodId') {
            const selectedMethod = paymentMethods.find(method => method.id === value);
            if (selectedMethod) {
                setForm(prevForm => ({
                    ...prevForm,
                    nameOnCard: selectedMethod?.billing_details?.name || '',
                    country: selectedMethod?.card?.country || 'US',
                    addressLine1: selectedMethod?.billing_details?.address?.line1 || '',
                    addressLine2: selectedMethod?.billing_details?.address?.line2 || '',
                    city: selectedMethod?.billing_details?.address?.city || '',
                    postalCode: selectedMethod?.billing_details?.address?.postal_code || '',
                    stateOrRegion: selectedMethod?.billing_details?.address?.state || '',
                    selectedPaymentMethodId: value,
                }));
            }
        } else {
            setForm(prevForm => ({ ...prevForm, [field]: value }));
        }
    };

    /**
     * Handles the form submission, including validation and organization creation.
     */
    const handleSubmit = async () => {
        setLoading(true);
        setError(''); // Assume you have an error state defined to handle errors

        // Check if Stripe is loaded and if the CardElement is available
        if (!stripe || !elements) {
            setError('Stripe has not been loaded. Please try again later.');
            setLoading(false);
            return;
        }

        // Validate the form fields
        if (!form.organizationName) {
            setError('Organization name is required.');
            setLoading(false);
            return;
        }

        // TODO: Add more form validations as needed

        try {
            // Create a payment method with Stripe
            let paymentMethodResult = {};
            if (!form?.selectedPaymentMethodId) {
                const cardElement = elements.getElement(CardElement);
                paymentMethodResult = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement,
                    billing_details: {
                        name: form.nameOnCard,
                        address: {
                            line1: form.addressLine1,
                            ...(form.addressLine2 && { line2: form.addressLine2 }),
                            city: form.city,
                            state: form.stateOrRegion,
                            postal_code: form.postalCode,
                            country: form.country,
                        },
                    },
                });

                if (paymentMethodResult.error) {
                    setError(paymentMethodResult.error.message);
                    setLoading(false);
                    return;
                }
            }

            // Prepare the organization data with the payment method ID
            const organizationData = {
                organization_name: form.organizationName,
                email: userEmail,
                payment_method_id: form.selectedPaymentMethodId || paymentMethodResult.paymentMethod.id,
                country: form.country,
                address_line_1: form.addressLine1,
                ...(form.addressLine2 && { address_line_2: form.addressLine2 }),
                city: form.city,
                postal_code: form.postalCode,
                state_or_region: form.stateOrRegion,
            };

            // Call the service to create the organization
            const response = await createOrganization(organizationData);

            // Handle the server response
            if (response) {
                console.log('Organization created successfully:', response);
            }

            // Close the modal on successful creation
            onOpenChange(false);
        } catch (error) {
            setError('An error occurred while creating the organization.');
        } finally {
            setLoading(false);
        }
    };

    // Render the modal with form inputs and submit button
    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            scrollBehavior='inside'
            style={{
                minWidth: '700px',
                minHeight: '100px'
            }}
            placement="top-center">
            <ModalContent>
                <ModalHeader>Create Organization</ModalHeader>
                <ModalBody>
                    {error && <Code color="danger">{error}</Code>}
                    <Input
                        clearable
                        variant="bordered"
                        placeholder="Organization Name"
                        onChange={(e) => handleInputChange('organizationName', e.target.value)}
                        value={form.organizationName}
                    />
                    <Input
                        placeholder="Enter email"
                        type="email"
                        variant="faded"
                        value={userEmail}
                        disabled={true}
                    />
                    <label><strong>Card Details</strong></label>
                    {paymentMethods.length > 0 && (
                        <Select
                            clearable
                            variant="bordered"
                            placeholder="Select a previous payment method"
                            onChange={(e) => handleInputChange('selectedPaymentMethodId', e.target.value)}
                            value={form.selectedPaymentMethodId}
                        >
                            {renderPaymentMethodOptions()}
                        </Select>
                    )}
                    {!form.selectedPaymentMethodId && (
                        <>
                            <div style={{
                                border: '1px solid #dee2e6',
                                borderRadius: '10px',
                                padding: '15px',
                                marginBottom: '10px',
                            }}>
                                <CardElement options={cardStyle} id="card-element" />
                            </div>
                            <Input
                                clearable
                                variant="bordered"
                                placeholder="Name on card"
                                onChange={(e) => handleInputChange('nameOnCard', e.target.value)}
                                value={form.nameOnCard}
                            />
                        </>
                    )}
                    {!form.selectedPaymentMethodId && (
                        <>
                            <label><strong>Billing Address</strong></label>
                            <Select
                                clearable
                                variant="bordered"
                                placeholder="Select country"
                                onChange={(e) => handleInputChange('country', e.target.value)}
                                value={form.country}
                            >
                                {countries.map((country) => (
                                    <SelectItem key={country.code} value={country.code}>
                                        {country.name}
                                    </SelectItem>
                                ))}
                            </Select>
                            <Input
                                clearable
                                variant="bordered"
                                placeholder="Address line 1"
                                onChange={(e) => handleInputChange('addressLine1', e.target.value)}
                                value={form.addressLine1}
                            />
                            <Input
                                clearable
                                variant="bordered"
                                placeholder="Address line 2"
                                onChange={(e) => handleInputChange('addressLine2', e.target.value)}
                                value={form.addressLine2}
                            />
                            <Row>
                                <Col>
                                    <Input
                                        clearable
                                        variant="bordered"
                                        placeholder="City"
                                        onChange={(e) => handleInputChange('city', e.target.value)}
                                        value={form.city}
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        clearable
                                        variant="bordered"
                                        placeholder="Postal code"
                                        onChange={(e) => handleInputChange('postalCode', e.target.value)}
                                        value={form.postalCode}
                                    />
                                </Col>
                            </Row>
                            <Input
                                clearable
                                variant="bordered"
                                placeholder="State or region"
                                onChange={(e) => handleInputChange('stateOrRegion', e.target.value)}
                                value={form.stateOrRegion}
                            />
                        </>
                    )}
                    <div style={{ marginTop: '20px' }}>
                        <Button
                            color="primary"
                            fullWidth
                            auto
                            onClick={handleSubmit}
                            disabled={loading}
                            style={{ marginBottom: '20px' }}
                        >
                            {loading ? <Spinner size='sm' color='white' /> : 'Create organization'}
                        </Button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateOrganizationModal;