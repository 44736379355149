import { FaCopy } from "react-icons/fa6";
import './CodeCopyBtn.css';
import { useState } from "react";

export const CodeCopyBtn = ({ children }) => {
    const [copyOk, setCopyOk] = useState(false);

    const iconColor = copyOk ? '#0af20a' : '#ddd';

    const handleClick = () => {
        navigator.clipboard.writeText(children);
        setCopyOk(true);
        setTimeout(() => {
            setCopyOk(false);
        }, 500);
    }

    return (
        <div className="code-copy-btn">
            <FaCopy onClick={handleClick} style={{ color: iconColor }} />
        </div>
    )
}
