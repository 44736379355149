// frontend/src/services/organizationService.js

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Handles the response from a fetch call.
 * 
 * @param {Response} response The response object from fetch.
 * @returns {Promise<Object>} A promise that resolves to the JSON body.
 * @throws {Error} If the response status is not OK.
 */
async function handleResponse(response) {
    if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
    }
    return response.json();
}

/**
 * Fetches billing information for a specific organization.
 * 
 * @param {string} organizationId The unique identifier of the organization to retrieve billing info for.
 * @returns {Promise<Object>} A promise that resolves to the organization's billing details.
 * @throws {Error} If fetching billing information fails.
 */
async function getOrganizationBilling(organizationId) {
    try {
        const response = await fetch(`${API_URL}/organizations/billing/${encodeURIComponent(organizationId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Fetching organization billing information failed:', error);
        throw error;
    }
}


/**
 * Fetches all users affiliated with a specific organization.
 * 
 * @param {string} organizationId The unique identifier of the organization to retrieve users for.
 * @returns {Promise<Array>} A promise that resolves to a list of users affiliated with the organization.
 * @throws {Error} If fetching users fails.
 */
async function getUsersByOrganization(organizationId) {
    try {
        const response = await fetch(`${API_URL}/organizations/users/${encodeURIComponent(organizationId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Fetching users by organization failed:', error);
        throw error;
    }
}

/**
 * Creates a new organization with the given data.
 * 
 * @param {Object} organizationData The data for the new organization.
 * @returns {Promise<Object>} A promise that resolves to the created organization details.
 * @throws {Error} If creating the organization fails.
 */
async function createOrganization(organizationData) {
    try {
        const response = await fetch(`${API_URL}/organizations/create-organization`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(organizationData)
        });
        return await handleResponse(response);
    } catch (error) {
        console.error('Creating organization failed:', error);
        throw error;
    }
}

export { getOrganizationBilling, getUsersByOrganization, createOrganization };