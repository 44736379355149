import { useState, useEffect } from "react";
import Navigation from "../../components/Navigation";
import { Button, Code, Input, Modal, ModalBody, ModalContent, ModalHeader, Spinner, useDisclosure, Textarea, ModalFooter } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import MonacoEditorComponent from "../../components/MonacoEditorComponent";
import { streamText } from 'ai';
import { createOpenAI } from '@ai-sdk/openai';
import './CodeCopyBtn.css';
import { ChatModal } from "../../components/ChatModal";
import { EditTitleModal } from "../../components/EditTitleModal";
import { PreviewModal } from "../../components/PreviewModal";
import { getPageById, updatePage } from "../../services/pagesService"; // Import the necessary functions

const openai = createOpenAI({
    compatibility: 'strict',
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

export default function IndividualPage({
    userData,
    organizations,
    permission,
    selectedOrganization,
    setSelectedOrganization,
    channel
}) {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose } = useDisclosure();
    const { isOpen: isChatOpen, onOpen: onChatOpen, onClose: onChatClose } = useDisclosure();
    const { pageId } = useParams(); // Get the page ID from the URL
    const [pageTitle, setPageTitle] = useState('');
    const [tempTitle, setTempTitle] = useState(pageTitle);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [code, setCode] = useState('');
    const [aiInput, setAiInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [path, setPath] = useState('');
    const [domain, setDomain] = useState(''); // Add state for domain

    useEffect(() => {
        async function loadPage() {
            try {
                const page = await getPageById(pageId);
                setPageTitle(page.title);
                setCode(page.content);
                setDomain(page.domain); // Set domain
                setPath(page.path);
            } catch (error) {
                setError(error.message);
            }
        }

        if (pageId) {
            loadPage();
        }
    }, [pageId]);

    const handleEditIconClick = () => {
        setTempTitle(pageTitle);
        onOpen();
    };

    const handleSavePageTitle = () => {
        setPageTitle(tempTitle);
        onClose();
    };

    const handleUpdate = async () => {
        if (!pageTitle) {
            setError('Title is required.');
            return;
        }

        setLoading(true);

        try {
            const pageData = {
                title: pageTitle,
                content: code,
                created_by: userData._id, // Add created_by field
                updated_by: userData._id,
                updated_date: new Date().toISOString(),
                domain: domain, // Use domain state
                path: path,
                organization_id: selectedOrganization._id,
            };
            await updatePage(pageId, pageData);
            navigate(`/orgs/${selectedOrganization._id}/assets/landing-pages`);
        } catch (error) {
            setError('Error updating page.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate(`/orgs/${selectedOrganization._id}/assets/landing-pages`);
    };

    const handleAiGenerate = () => {
        setCode(`<html>\n<div>\n  <h1>${aiInput}</h1>\n  <p>This is AI-generated content.</p>\n</div>\n</html>`);
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSubmit = async (e, context) => {
        e.preventDefault();
        if (!input.trim()) return;

        setIsChatLoading(true);
        try {
            const newMessages = [
                ...messages,
                { id: Date.now(), role: 'user', content: input },
                { id: Date.now() + 1, role: 'assistant', content: '' }
            ];
            setMessages(newMessages);
            setInput('');

            const previousContext = newMessages.slice(-10).map(msg => `${msg.role === 'user' ? 'You' : 'AI'}: ${msg.content}`).join('\n');
            const fullPrompt = `${previousContext}\nYou: ${input}\nContext: ${context}\nAI:`;

            const { textStream } = await streamText({
                model: openai("gpt-4-turbo"),
                system: 'You are an expert at creating landing pages in HTML. You will be creating new landing pages for websites. You will not include any links to favicons unless clearly specified by the user. All CSS will be put in the <style> tag in the <head> section. You will not include any JavaScript code unless clearly specified. All your landing pages will be structured and styled like a SaaS Next.js site styled with TailwindCSS. You will always return HTML content for the landing pages.',
                prompt: fullPrompt,
            });

            let generatedText = '';
            for await (const textPart of textStream) {
                generatedText += textPart;
                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    updatedMessages[updatedMessages.length - 1].content = generatedText;
                    return updatedMessages;
                });
            }

            setInput('');
        } catch (error) {
            console.error('Error generating text:', error);
        } finally {
            setIsChatLoading(false);
        }
    };

    return (
        <div>
            <Navigation
                userType={permission?.type}
                userData={userData}
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
            />
            <div style={{
                padding: '.5rem',
                display: 'flex'
            }}>
                <h1
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        flex: 1
                    }}
                >
                    {pageTitle} <span style={{ display: 'inline-block', cursor: 'pointer' }} onClick={handleEditIconClick}>✏️</span>
                </h1>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="primary"
                        onClick={onPreviewOpen}
                    >Preview</Button>
                    <Button
                        color="secondary"
                        onClick={onChatOpen}
                        style={{ marginLeft: '1rem' }}
                    >Ask AI</Button>
                </div>
            </div>
            <EditTitleModal
                isOpen={isOpen}
                onClose={onClose}
                tempTitle={tempTitle}
                setTempTitle={setTempTitle}
                handleSavePageTitle={handleSavePageTitle}
            />
            <PreviewModal
                isOpen={isPreviewOpen}
                onClose={onPreviewClose}
                code={code}
            />
            <ChatModal
                isOpen={isChatOpen}
                onClose={onChatClose}
                messages={messages}
                input={input}
                setInput={setInput}
                handleSubmit={handleSubmit}
                isChatLoading={isChatLoading}
                code={code}
            />
            <div style={{ padding: '.5rem', maxWidth: '800px' }}>
                {error && <Code color="danger" style={{ marginBottom: '10px' }}>{error}</Code>}
                <div>
                    <h3
                        style={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                        }}
                    >Domain: {domain}</h3> {/* Display domain */}
                </div>
                <MonacoEditorComponent value={code} onChange={setCode} />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <div>
                        <Button
                            color="primary"
                            style={{ marginRight: '1rem' }}
                            onClick={handleUpdate}
                        >
                            {loading ? <Spinner size='sm' color='white' /> : 'Save'}
                        </Button>
                        <Button
                            color="secondary"
                            style={{ marginRight: '1rem' }}
                            onClick={handleCancel}
                        >Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}