import React, { useEffect, useState, useCallback, lazy, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../utils/firebase';
import { getUserByEmail, getUserOrganizations, getUserOrganizationPermission } from '../services/userService';
import { getOrganizationBilling } from '../services/organizationService';
import { Spinner } from "@nextui-org/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import Pages from '../pages/sms/Pages';
import CreatePage from '../pages/sms/CreatePage';
import IndividualPage from '../pages/sms/IndividalPage';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const Team = lazy(() => import('../pages/organizationSettings/Team'));
const Billing = lazy(() => import('../pages/organizationSettings/Billing'));
const Invites = lazy(() => import('../pages/profile/Invites'));
const Templates = lazy(() => import('../pages/sms/templates/Templates'));
const CreateTemplate = lazy(() => import('../pages/sms/templates/CreateTemplate'));
const IndividualTemplate = lazy(() => import('../pages/sms/templates/IndividualTemplate'));
const Recipients = lazy(() => import('../pages/sms/recipients/Recipients'));
const Campaigns = lazy(() => import('../pages/sms/campaigns/Campaigns'));
const Groups = lazy(() => import('../pages/sms/groups/Groups'));
const CreateCampaign = lazy(() => import('../pages/sms/campaigns/CreateCampaign'));
const PhoneNumbers = lazy(() => import('../pages/sms/PhoneNumbers'));
const ViewCampaign = lazy(() => import('../pages/sms/campaigns/ViewCampaign'));
const Message = lazy(() => import('../pages/sms/messages/Message'));
const Automations = lazy(() => import('../pages/automations/Automations'));
const RequestAutomation = lazy(() => import('../pages/automations/RequestAutomation'));
const IndividualAutomation = lazy(() => import('../pages/automations/IndividualAutomation'));
const EmailAddresses = lazy(() => import('../pages/email/EmailAddresses'));
const Domains = lazy(() => import('../pages/sms/Domains'));
const Inbox = lazy(() => import('../pages/email/Inbox'));

// Initialize Stripe with the publishable key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY);

const DashboardGuard = ({ updateTitle }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [permission, setPermission] = useState(null);
    const [billingInfo, setBillingInfo] = useState(null);
    const [selectedOrganization, setSelectedOrganization] = useState(null);

    const fetchUserData = useCallback(async (firebaseUser) => {
        try {
            const userEmail = firebaseUser.email;
            const userInfo = await getUserByEmail(userEmail);
            if (!userInfo) {
                throw new Error('User information not found.');
            }
            setUserData(userInfo);

            const userOrganizations = await getUserOrganizations(userEmail);
            if (userOrganizations.length === 0) {
                throw new Error('No organizations found for this user.');
            }
            setOrganizations(userOrganizations);

            const pathOrganizationId = location.pathname.split('/orgs/')[1]?.split('/')[0];
            const hasPermissionForPathOrganization = userInfo.permission.some(p => p?.organization_id === pathOrganizationId);

            if (pathOrganizationId && hasPermissionForPathOrganization) {
                const organization = userOrganizations.find(org => org?._id === pathOrganizationId);
                setSelectedOrganization(organization);
            } else {
                const defaultPermission = userInfo.permission.find(p => !p?.invited);
                if (defaultPermission) {
                    navigate(`/orgs/${defaultPermission.organization_id}/dashboard`);
                    return;
                }
            }
        } catch (error) {
            navigate('/login');
            console.error('DashboardGuard encountered an error:', error);
        } finally {
            setLoading(false);
        }
    }, [navigate, location.pathname]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
            if (!firebaseUser) {
                navigate('/login');
                return;
            }
            fetchUserData(firebaseUser);
        });

        return () => unsubscribe();
    }, [fetchUserData, navigate]);

    useEffect(() => {
        const fetchBillingInfo = async () => {
            if (selectedOrganization?._id) {
                try {
                    const orgBillingInfo = await getOrganizationBilling(selectedOrganization._id);
                    if (!orgBillingInfo) {
                        throw new Error('Billing information not found.');
                    }
                    setBillingInfo(orgBillingInfo);
                } catch (error) {
                    console.error('Error fetching billing info:', error);
                }
            }
        };

        fetchBillingInfo();
    }, [selectedOrganization]);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (userData?._id && selectedOrganization?._id) {
                try {
                    const permissionDetails = await getUserOrganizationPermission(userData._id, selectedOrganization._id);
                    if (!permissionDetails) {
                        throw new Error('Permission not found.');
                    }
                    setPermission(permissionDetails);
                } catch (error) {
                    console.error('Error fetching user permissions:', error);
                }
            }
        };

        fetchPermissions();
    }, [userData, selectedOrganization]);

    const renderComponent = () => {
        const path = location.pathname;
        if (path.includes('/dashboard')) {
            updateTitle('withcold | Dashboard');
            return <Dashboard {...{ userData, organizations, permission, billingInfo, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/team')) {
            updateTitle('withcold | Team');
            return <Team {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/billing')) {
            updateTitle('withcold | Billing');
            return (
                <Elements stripe={stripePromise}>
                    <Billing {...{ billingInfo, selectedOrganization, organizations, permission, setSelectedOrganization, userData }} />
                </Elements>
            );
        } else if (path.includes('/invites')) {
            updateTitle('withcold | Invites');
            return <Invites {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/sms/templates/individual-template')) {
            updateTitle('withcold | Individual SMS Template');
            return <IndividualTemplate {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/sms/templates/create-template')) {
            updateTitle('withcold | Create SMS Template');
            return <CreateTemplate {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/sms/templates')) {
            updateTitle('withcold | SMS Templates');
            return <Templates {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/sms/recipients')) {
            updateTitle('withcold | SMS Recipients');
            return <Recipients {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/sms/groups')) {
            updateTitle('withcold | SMS Groups');
            return <Groups {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/sms/campaigns/create-campaign')) {
            updateTitle('withcold | Create SMS Campaign');
            return <CreateCampaign {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: "sms" }} />;
        } else if (path.includes('/sms/campaigns/campaign/')) {
            updateTitle('withcold | View SMS Campaign');
            return <ViewCampaign {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: "sms" }} />;
        } else if (path.includes('/sms/campaigns')) {
            updateTitle('withcold | SMS Campaigns');
            return <Campaigns {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/sms/phone-numbers')) {
            updateTitle('withcold | SMS Phone Numbers');
            return <PhoneNumbers {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/sms/messages/')) {
            updateTitle('withcold | SMS Messages');
            return <Message {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/sms/automations/request-automation')) {
            updateTitle('withcold | Request SMS Automation');
            return <RequestAutomation {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/sms/automations/automation/')) {
            updateTitle('withcold | Individual SMS Automation');
            return <IndividualAutomation {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/sms/automations')) {
            updateTitle('withcold | SMS Automations');
            return <Automations {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'sms' }} />;
        } else if (path.includes('/email/templates/individual-template')) {
            updateTitle('withcold | Individual Template');
            return <IndividualTemplate {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/templates/create-template')) {
            updateTitle('withcold | Create Template');
            return <CreateTemplate {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/templates')) {
            updateTitle('withcold | Templates');
            return <Templates {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/email-addresses')) {
            updateTitle('withcold | Email Addresses');
            return <EmailAddresses {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/email/recipients')) {
            updateTitle('withcold | Recipients');
            return <Recipients {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/groups')) {
            updateTitle('withcold | Groups');
            return <Groups {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/campaigns/create-campaign')) {
            updateTitle('withcold | Create Campaign');
            return <CreateCampaign {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: "email" }} />;
        } else if (path.includes('/email/campaigns/campaign/')) {
            updateTitle('withcold | View Campaign');
            return <ViewCampaign {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: "email" }} />;
        } else if (path.includes('/email/campaigns')) {
            updateTitle('withcold | Campaigns');
            return <Campaigns {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/automations/request-automation')) {
            updateTitle('withcold | Request Automation');
            return <RequestAutomation {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/automations/automation/')) {
            updateTitle('withcold | Individual Automation');
            return <IndividualAutomation {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/automations')) {
            updateTitle('withcold | Automations');
            return <Automations {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization, channel: 'email' }} />;
        } else if (path.includes('/email/domains')) {
            updateTitle('withcold | Domains');
            return <Domains {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/email/inbox')) {
            updateTitle('withcold | Inbox');
            return <Inbox {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/assets/pages/individual-page')) {
            updateTitle('withcold | Individual Page');
            return <IndividualPage {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/assets/landing-pages')) {
            updateTitle('withcold | Landing Pages');
            return <Pages {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        } else if (path.includes('/assets/create-page')) {
            updateTitle('withcold | Create Page');
            return <CreatePage {...{ userData, organizations, permission, selectedOrganization, setSelectedOrganization }} />;
        }
    };

    return loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Spinner />
        </div>
    ) : (
        <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Spinner /></div>}>
            {renderComponent()}
        </Suspense>
    );
};

export default DashboardGuard;