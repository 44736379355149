// frontend/src/guards/LoginGuard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { getUserByEmail } from '../services/userService';
import Login from '../pages/Login';

/**
 * LoginGuard is a higher-order component that manages the authentication state
 * of a user. It listens for changes in the authentication state using Firebase's
 * onAuthStateChanged method. If a user is authenticated, it attempts to fetch
 * the user's data and navigates to the user's first organization dashboard if available.
 * Unauthenticated users are redirected to the Login page.
 *
 * @returns {React.ReactElement} Renders the Login component for unauthenticated users,
 * a loading indicator or null while the authentication state is being determined.
 */
const LoginGuard = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        // Subscription to auth state changes
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                setIsAuthenticated(true);
                try {
                    const userInfo = await getUserByEmail(firebaseUser.email);
                    if (userInfo && userInfo.organizations.length > 0) {
                        navigate(`/orgs/${userInfo.organizations[0]}/dashboard`);
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else {
                setIsAuthenticated(false);
            }
        });

        // Cleanup function to unsubscribe from auth state changes
        return () => unsubscribe();
    }, [navigate]);

    // Render the Login component if the user is not authenticated
    if (isAuthenticated === false) {
        return <Login />;
    }

    // Render a loading indicator or null while determining the authentication state
    // Here you can replace `null` with a loading spinner if desired
    return isAuthenticated === null ? null : null;
};

export default LoginGuard;