import { useState, useEffect, useRef } from "react";
import Navigation from "../../components/Navigation";
import { Button, Code, Input, Modal, ModalBody, ModalContent, ModalHeader, Select, SelectItem, Spinner, useDisclosure, Textarea, ModalFooter } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { getDomainsByOrganization } from "../../services/emailAddressService";
import MonacoEditorComponent from "../../components/MonacoEditorComponent";
import { streamText } from 'ai';
import { createOpenAI } from '@ai-sdk/openai';
import './CodeCopyBtn.css';
import { ChatModal } from "../../components/ChatModal";
import { EditTitleModal } from "../../components/EditTitleModal";
import { PreviewModal } from "../../components/PreviewModal";
import { uploadHtml } from "../../services/pagesService"; // Import the uploadHtml function

const openai = createOpenAI({
    // custom settings, e.g.
    compatibility: 'strict', // strict mode, enable when using the OpenAI API
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

export default function CreatePage({
    userData,
    organizations,
    permission,
    selectedOrganization,
    setSelectedOrganization,
    channel
}) {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose } = useDisclosure();
    const { isOpen: isChatOpen, onOpen: onChatOpen, onClose: onChatClose } = useDisclosure();
    const [pageTitle, setPageTitle] = useState('New Page');
    const [tempTitle, setTempTitle] = useState(pageTitle);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [domains, setDomains] = useState([]);
    const [selectedDomain, setSelectedDomain] = useState('');
    const [domainLoading, setDomainLoading] = useState(false);
    const [code, setCode] = useState('<html>\n<div>\n  <h1>Hello World</h1>\n  <p>This is a default HTML content.</p>\n</div>\n</html>');
    const [aiInput, setAiInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [path, setPath] = useState('');

    useEffect(() => {
        const fetchTemplates = async () => {
            // Placeholder for fetching templates
            // Simulate a delay
            await new Promise(resolve => setTimeout(resolve, 500));
            // Set dummy templates
            setTemplates([
                { id: '1', name: 'Template 1' },
                { id: '2', name: 'Template 2' }
            ]);
        };

        fetchTemplates();
    }, []);

    useEffect(() => {
        const fetchDomains = async () => {
            setDomainLoading(true);
            try {
                const data = await getDomainsByOrganization(selectedOrganization._id);
                const route53Domains = data.filter(domain => domain.Service === 'Route53');
                setDomains(route53Domains);
            } catch (error) {
                console.error('Error fetching domains:', error);
            } finally {
                setDomainLoading(false);
            }
        };

        if (selectedOrganization) {
            fetchDomains();
        }
    }, [selectedOrganization]);

    const handleEditIconClick = () => {
        setTempTitle(pageTitle);
        onOpen();
    };

    const handleSavePageTitle = () => {
        setPageTitle(tempTitle);
        onClose();
    };

    const handleSave = async () => {
        if (!pageTitle) {
            setError('Title is required.');
            return;
        }

        setLoading(true);

        try {
            // Call the uploadHtml function to upload the HTML content
            const response = await uploadHtml(selectedDomain, path, code, selectedOrganization?._id, userData?._id, userData?._id);
            if (response.status === 'success') {
                navigate(`/orgs/${selectedOrganization._id}/assets/landing-pages`);
            } else {
                setError('Error uploading HTML content.');
            }
        } catch (error) {
            setError('Error creating page.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate(`/orgs/${selectedOrganization._id}/assets/landing-pages`);
    };

    const handleAiGenerate = () => {
        // Placeholder for AI generation logic
        setCode(`<html>\n<div>\n  <h1>${aiInput}</h1>\n  <p>This is AI-generated content.</p>\n</div>\n</html>`);
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSubmit = async (e, context) => {
        e.preventDefault();
        if (!input.trim()) return;

        setIsChatLoading(true);
        try {
            // Add the user's message first
            const newMessages = [
                ...messages,
                { id: Date.now(), role: 'user', content: input },
                { id: Date.now() + 1, role: 'assistant', content: '' } // Placeholder for AI response
            ];
            setMessages(newMessages);
            setInput('');

            // Construct the prompt with the context of previous messages
            const previousContext = newMessages.slice(-10).map(msg => `${msg.role === 'user' ? 'You' : 'AI'}: ${msg.content}`).join('\n');
            const fullPrompt = `${previousContext}\nYou: ${input}\nContext: ${context}\nAI:`;

            const { textStream } = await streamText({
                model: openai("gpt-4-turbo"),
                prompt: fullPrompt,
            });

            let generatedText = '';
            for await (const textPart of textStream) {
                generatedText += textPart;
                setMessages((prevMessages) => {
                    const updatedMessages = [...prevMessages];
                    // Update the last message (AI response) with the new text
                    updatedMessages[updatedMessages.length - 1].content = generatedText;
                    return updatedMessages;
                });
            }

            setInput('');
        } catch (error) {
            console.error('Error generating text:', error);
        } finally {
            setIsChatLoading(false);
        }
    };

    return (
        <div>
            <Navigation
                userType={permission?.type}
                userData={userData}
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
            />
            <div style={{
                padding: '.5rem'
                , display: 'flex'
            }}>
                <h1
                    style={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        marginTop: '1rem',
                        flex: 1
                    }}
                >
                    {pageTitle} <span style={{ display: 'inline-block', cursor: 'pointer' }} onClick={handleEditIconClick}>✏️</span>
                </h1>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="primary"
                        onClick={onPreviewOpen}
                    >Preview</Button>
                    <Button
                        color="secondary"
                        onClick={onChatOpen}
                        style={{ marginLeft: '1rem' }}
                    >Ask AI</Button>
                </div>
            </div>
            <EditTitleModal
                isOpen={isOpen}
                onClose={onClose}
                tempTitle={tempTitle}
                setTempTitle={setTempTitle}
                handleSavePageTitle={handleSavePageTitle}
            />
            <PreviewModal
                isOpen={isPreviewOpen}
                onClose={onPreviewClose}
                code={code}
            />
            <ChatModal
                isOpen={isChatOpen}
                onClose={onChatClose}
                messages={messages}
                input={input}
                setInput={setInput}
                handleSubmit={handleSubmit}
                isChatLoading={isChatLoading}
                code={code} // Pass the code to the ChatModal
            />
            <div style={{ padding: '.5rem', maxWidth: '800px' }}>
                {error && <Code color="danger" style={{ marginBottom: '10px' }}>{error}</Code>}
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', marginBottom: '1rem' }}>
                    <Select
                        placeholder="Select a domain"
                        value={selectedDomain}
                        onChange={(e) => setSelectedDomain(e.target.value)}
                        variant="bordered"
                        isDisabled={domains.length === 0 || domainLoading}
                    >
                        {domains.map((domain) => (
                            <SelectItem key={domain.DomainName} value={domain.DomainName}>
                                {domain.DomainName}
                            </SelectItem>
                        ))}
                    </Select>
                    <Input
                        placeholder="Enter the path (e.g., /about-us)"
                        value={path}
                        onChange={(e) => setPath(e.target.value)}
                        variant="bordered"
                    // fullWidth
                    />
                    <Select
                        placeholder="Select a template (optional)"
                        value={selectedTemplate}
                        onChange={(e) => setSelectedTemplate(e.target.value)}
                        variant="bordered"
                        isDisabled={templates.length === 0}
                    >
                        {templates.map((template) => (
                            <SelectItem key={template.id} value={template.id}>
                                {template.name}
                            </SelectItem>
                        ))}
                    </Select>
                </div>
                <MonacoEditorComponent value={code} onChange={setCode} />
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <div>
                        <Button
                            color="primary"
                            style={{ marginRight: '1rem' }}
                            onClick={handleSave}
                        >
                            {loading ? <Spinner size='sm' color='white' /> : 'Save'}
                        </Button>
                        <Button
                            color="secondary"
                            style={{ marginRight: '1rem' }}
                            onClick={handleCancel}
                        >Cancel</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}