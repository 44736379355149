const PAGES_API_URL = `${process.env.REACT_APP_API_URL}/pages`;

/**
 * Handles the response from a fetch call.
 * 
 * @param {Response} response The response object from fetch.
 * @returns {Promise<Object>} A promise that resolves to the JSON body.
 * @throws {Error} If the response status is not OK.
 */
async function handleResponse(response) {
    if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
    }
    return response.json();
}

/**
 * Uploads HTML content to a specified domain and route.
 * 
 * @param {string} domain The domain name to configure.
 * @param {string} route The route for the HTML file.
 * @param {string} htmlContent The HTML content to upload.
 * @param {string} organizationId The unique identifier of the organization.
 * @param {string} createdBy The identifier of the user who created the page.
 * @param {string} updatedBy The identifier of the user who last updated the page.
 * @returns {Promise<Object>} A promise that resolves to the response from the server.
 */
async function uploadHtml(domain, route, htmlContent, organizationId, createdBy, updatedBy) {
    const response = await fetch(`${PAGES_API_URL}/upload-html`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ domain, route, html_content: htmlContent, organization_id: organizationId, created_by: createdBy, updated_by: updatedBy })
    });
    return handleResponse(response);
}

/**
 * Retrieves a page by its ID.
 * 
 * @param {string} pageId The unique identifier of the page.
 * @returns {Promise<Object>} A promise that resolves to the page data.
 */
async function getPageById(pageId) {
    const response = await fetch(`${PAGES_API_URL}/pages/${pageId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return handleResponse(response);
}

/**
 * Updates a page by its ID.
 * 
 * @param {string} pageId The unique identifier of the page.
 * @param {Object} pageData The updated page data.
 * @returns {Promise<Object>} A promise that resolves to the updated page data.
 */
async function updatePage(pageId, pageData) {
    const response = await fetch(`${PAGES_API_URL}/pages/${pageId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pageData)
    });
    return handleResponse(response);
}

/**
 * Deletes a page by its ID.
 * 
 * @param {string} pageId The unique identifier of the page.
 * @returns {Promise<Object>} A promise that resolves to the status of the operation.
 */
async function deletePage(pageId) {
    const response = await fetch(`${PAGES_API_URL}/pages/${pageId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return handleResponse(response);
}

/**
 * Retrieves pages for a specific organization.
 * 
 * @param {string} organizationId The unique identifier of the organization.
 * @param {number} page The page number for pagination.
 * @param {number} pageSize The number of pages to retrieve per page.
 * @returns {Promise<Array>} A promise that resolves to a list of pages for the specified organization.
 */
async function getPagesByOrganization(organizationId, page = 1, pageSize = 10) {
    const response = await fetch(`${PAGES_API_URL}/organizations/${organizationId}/pages?page=${page}&page_size=${pageSize}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return handleResponse(response);
}

export { uploadHtml, getPageById, updatePage, deletePage, getPagesByOrganization };