import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../contexts/RegistrationContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BillingDetails from "../../pages/registration/BillingDetails";

// Initialize Stripe with the publishable key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY);

/**
 * BillingDetailsGuard is a higher-order component that wraps the BillingDetails
 * component with additional checks and behaviors. It ensures that all necessary
 * registration information is provided before the user can access the billing
 * details page. If any required information is missing, the user is redirected
 * to the user-details page. It also sets up a navigation guard to prevent data
 * loss when the page is reloaded or closed.
 *
 * @returns {React.ReactElement} A Stripe Elements wrapper containing the BillingDetails
 * component if all registration data is present, or performs a redirection to the
 * user-details page if required data is missing.
 */
export const BillingDetailsGuard = () => {
    const navigate = useNavigate();
    const { registrationData } = useRegistration();

    useEffect(() => {
        // Check for the presence of required registration fields
        const isRegistrationDataComplete = registrationData.first_name &&
            registrationData.last_name &&
            registrationData.email &&
            registrationData.password &&
            registrationData.organization_name;

        // Redirect to the user-details page if any required field is missing
        if (!isRegistrationDataComplete) {
            navigate('/register/user-details');
            return; // Early return to prevent further execution and event listener attachment
        }

        // Handler to prevent data loss on page navigation or closure
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Chrome requires returnValue to be set
        };

        // Attach the beforeunload event listener to prevent data loss
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove the event listener when the component unmounts
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [navigate, registrationData]); // Dependencies for useEffect to control re-execution

    // Render the BillingDetails component within a Stripe Elements context
    return (
        <Elements stripe={stripePromise}>
            <BillingDetails />
        </Elements>
    );
};