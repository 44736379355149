import React from 'react';

const Landing = () => {
    const styles = {
        navBar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 50px',
            backgroundColor: 'transparent', // Changed to a more modern look
            color: '#ffffff',
            height: '80px', // Increased height for a more spacious look
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Soft shadow for depth
            transition: 'background-color 0.3s ease', // Smooth transition for hover effects
        },
        navLinks: {
            display: 'flex',
            listStyle: 'none',
            gap: '20px',
        },
        navLink: {
            textDecoration: 'none',
            color: 'black',
            fontSize: '1rem',
            fontWeight: 'bold',
            padding: '10px 15px', // Padding for a larger clickable area
            borderRadius: '5px', // Rounded corners for the hover effect background
            transition: 'background-color 0.3s ease', // Smooth transition for hover effects
            ':hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)', // Subtle hover effect
            },
        },
        logo: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
            color: '#007bff', // Logo color to stand out
            textDecoration: 'none',
        },
        // Additional styles for responsiveness
        navToggle: {
            display: 'none',
            fontSize: '1.5rem',
            color: '#007bff',
            cursor: 'pointer',
        },
        // Responsive design adjustments
        '@media (max-width: 768px)': {
            navBar: {
                flexDirection: 'column',
                padding: '20px',
            },
            navLinks: {
                flexDirection: 'column',
                alignItems: 'center',
                gap: '10px',
            },
            navToggle: {
                display: 'block',
            },
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            padding: '20px',
            backgroundColor: '#ffffff',
        },
        heroSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '90vh', // Adjusted for full screen hero section
            color: '#ffffff',
            textAlign: 'center',
            background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.3))', // Enhanced linear gradient for a smoother transition
            padding: '20px',
        },
        heroHeader: {
            fontSize: '3.5rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            color: '#007bff', // Adjusted for better visibility against potential background images
        },
        heroSubHeader: {
            fontSize: '2rem',
            maxWidth: '650px',
            marginBottom: '2rem',
            color: 'black', // Adjusted for better visibility against potential background images
        },
        heroButton: {
            padding: '15px 30px',
            fontSize: '1.25rem',
            backgroundColor: '#007bff',
            color: '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            textDecoration: 'none',
            transition: 'background-color 0.3s ease',
            // Shadow for better visibility
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        },
        header: {
            fontSize: '4rem',
            color: '#007bff',
            marginBottom: '0.5rem',
        },
        subHeader: {
            fontSize: '2rem',
            color: '#343a40',
            maxWidth: '650px',
            marginBottom: '2rem',
        },
        featureContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: '30px',
            marginBottom: '3rem',
        },
        feature: {
            width: '300px',
            padding: '30px',
            border: '1px solid #dee2e6',
            borderRadius: '15px',
            boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
            backgroundColor: '#ffffff',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            cursor: 'pointer',
        },
        featureHeader: {
            fontSize: '2rem',
            color: '#007bff',
            marginBottom: '1rem',
        },
        button: {
            padding: '12px 30px',
            border: 'none',
            borderRadius: '8px',
            backgroundColor: '#007bff',
            color: '#ffffff',
            fontSize: '1.25rem',
            cursor: 'pointer',
            textDecoration: 'none',
            transition: 'background-color 0.3s ease',
            display: 'inline-block',
            marginTop: '2rem',
        },
        testimonials: {
            fontStyle: 'italic',
            color: '#495057',
            marginBottom: '3rem',
        },
        testimonialAuthor: {
            color: '#007bff',
            fontWeight: 'bold',
        },
        footer: {
            marginTop: 'auto',
            fontSize: '1rem',
            color: '#6c757d',
            width: '100%',
            textAlign: 'center',
            padding: '2rem',
        },
    };

    return (
        <>
            <nav style={styles.navBar}>
                <a href="/" style={styles.logo}>Outbnnd</a>
                <div style={styles.navToggle}>☰</div>
                <ul style={styles.navLinks}>
                    <li><a href="#features" style={styles.navLink}>Features</a></li>
                    <li><a href="#pricing" style={styles.navLink}>Pricing</a></li>
                    <li><a href="https://outbnnd.com/login" style={styles.navLink}>Login</a></li>
                </ul>
            </nav>
            <div style={styles.container}>
                <div style={styles.heroSection}>
                    <h1 style={styles.heroHeader}>Empower Your Marketing</h1>
                    <p style={styles.heroSubHeader}>Utilize our cutting-edge tools to drive results and transform your marketing efforts.</p>
                    <a href="https://outbnnd.com/register/start-point" style={styles.heroButton}>Get Started Today</a>
                </div>
                <div style={styles.featureContainer} id="features">
                    {['SMS Outbound', 'Email Outbound', 'Automations', 'Analytics'].map((feature, index) => (
                        <div style={styles.feature} key={index} onClick={() => { }}>
                            <h2 style={styles.featureHeader}>{feature}</h2>
                            <p>Transform your marketing efforts and drive results with our {feature.toLowerCase()} feature.</p>
                        </div>
                    ))}
                </div>

                <a href="https://outbnnd.com/register/start-point" style={styles.button}>Get Started Today                </a>
                <div style={styles.testimonials}>
                    <p>"Outbnnd has revolutionized the way we reach our customers. The ease of use and effectiveness is unmatched."</p>
                    <p style={styles.testimonialAuthor}>- Marketing Director, Tech Innovations Inc.</p>
                </div>
            </div>
            <footer style={styles.footer}>
                © {new Date().getFullYear()} Outbnnd. All rights reserved.
            </footer>
        </>
    );
};

export default Landing;