const API_URL = `${process.env.REACT_APP_API_URL}/ai`;

/**
 * Handles the response from a fetch call.
 * 
 * @param {Response} response The response object from fetch.
 * @returns {Promise<Object>} A promise that resolves to the JSON body.
 * @throws {Error} If the response status is not OK.
 */
async function handleResponse(response) {
    if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
    }
    return response.json();
}

/**
 * Fetches the content of a given URL.
 * 
 * @param {string} url The URL to fetch content from.
 * @returns {Promise<Object>} A promise that resolves to an object containing the fetched content.
 */
async function fetchSiteContent(url) {
    const response = await fetch(`${API_URL}/fetch-site-content?url=${encodeURIComponent(url)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return handleResponse(response);
}

/**
 * Fetches the HTML content of a given URL.
 * 
 * @param {string} url The URL to fetch HTML content from.
 * @returns {Promise<Object>} A promise that resolves to an object containing the fetched HTML content.
 */
async function fetchSiteHtml(url) {
    const response = await fetch(`${API_URL}/fetch-site-html?url=${encodeURIComponent(url)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return handleResponse(response);
}

export {
    fetchSiteContent,
    fetchSiteHtml
};