import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../../utils/firebase';
import { getUserById, getUserOrganizations } from '../../services/userService';
import AcceptInvite from "../../pages/registration/AcceptInvite";
import { Spinner } from '@nextui-org/react';

/**
 * The AcceptInviteGuard component manages the invitation acceptance process.
 * It checks the user's authentication status and existence, then redirects
 * to the appropriate page within the application.
 *
 * @returns {React.ReactElement} Renders the AcceptInvite component if the user's
 * email is available, a Spinner while loading, or null if the user's email is not available.
 */
export const AcceptInviteGuard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [userState, setUserState] = useState({ email: null, data: null, loading: true });

    // Extract the userId from the URL path
    const [userId, organizationId] = location.pathname.split('/accept-invite/')[1].split('/');

    /**
     * Fetches user data using the userId and updates the component state.
     * Redirects to the login page if the userId is invalid.
     */
    const fetchUserData = useCallback(async () => {
        if (!userId) {
            navigate('/login');
            return;
        }

        try {
            const userInfo = await getUserById(userId);
            setUserState({ email: userInfo.email, data: userInfo, loading: false });
        } catch (error) {
            console.error('Error fetching user data:', error);
            navigate('/login');
        }
    }, [userId, navigate]);

    // Fetch user data when the component mounts
    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    /**
     * Sets up an authentication state change listener and handles user redirection.
     * Redirects to the first organization's invite page if the authenticated user's
     * email matches the invited user's email. Cleans up the listener on unmount.
     */
    useEffect(() => {
        if (!userState.email) return;

        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser?.email === userState.email) {
                try {
                    const organizations = await getUserOrganizations(userState.email);
                    if (organizations.length > 0) {
                        navigate(`/orgs/${organizations[0]._id}/profile-settings/invites`);
                    }
                } catch (error) {
                    console.error('Error fetching organizations:', error);
                    navigate('/login');
                }
            }
        });

        // Cleanup the listener on component unmount
        return unsubscribe;
    }, [userState.email, navigate]);

    // Display a spinner while loading user data
    if (userState.loading) {
        return <Spinner />;
    }

    // Render the AcceptInvite component if the user's email is available, otherwise render nothing
    return userState.email ? <AcceptInvite userData={userState.data} organizationId={organizationId} /> : null;
};