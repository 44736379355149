import { Button, Input, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Pagination, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import Navigation from "../../components/Navigation";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getPagesByOrganization } from "../../services/pagesService"; // Import the getPagesByOrganization function

export default function Pages({
    userData,
    organizations,
    permission,
    selectedOrganization,
    setSelectedOrganization,
    channel
}) {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const pageSize = 10;
    const [pages, setPages] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewHtml, setPreviewHtml] = useState('');
    const [iframeLoading, setIframeLoading] = useState(true);

    useEffect(() => {
        if (selectedOrganization) {
            fetchPages(page, searchValue);
        }
    }, [selectedOrganization, page]);

    const fetchPages = async (page, search = '') => {
        setLoading(true);
        try {
            const response = await getPagesByOrganization(selectedOrganization?._id, page, pageSize, search);
            setPages(response);
            setTotalPages(response.length);
        } catch (error) {
            console.error('Error fetching pages:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (newValue) => {
        setSearchValue(newValue);
        setPage(1);
        fetchPages(1, newValue);
    };

    const handlePreviewClick = (html) => {
        setPreviewHtml(html);
        setIframeLoading(true);
        setIsPreviewModalOpen(true);
    };

    return (
        <div>
            <Navigation
                userType={permission?.type}
                userData={userData}
                organizations={organizations}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
            />
            <div style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
                <Button
                    style={{ marginTop: '1rem', marginBottom: '1rem', float: 'right' }}
                    color="primary"
                    onClick={() => navigate(`/orgs/${selectedOrganization?._id}/assets/create-page`)}
                >
                    Create Page
                </Button>
                <br />
                <br />
                <br />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <Input
                        isClearable
                        variant="bordered"
                        className="w-full sm:max-w-[44%]"
                        placeholder="Search by name..."
                        value={searchValue}
                        onClear={() => handleSearchChange('')}
                        onValueChange={handleSearchChange}
                    />
                </div>
                <br />
                {loading ? (
                    <div className="flex w-full justify-center">
                        <Spinner />
                    </div>
                ) : pages?.length === 0 ? (
                    <div className="flex w-full justify-center">
                        <p>No pages found.</p>
                    </div>
                ) : (
                    <Table
                        bottomContent={
                            page > 0 ? (
                                <div className="flex w-full justify-center">
                                    <Pagination
                                        isCompact
                                        showControls
                                        showShadow
                                        color="primary"
                                        page={page}
                                        total={Math.ceil(totalPages / pageSize)}
                                        onChange={(newPage) => {
                                            setPage(newPage);
                                            fetchPages(newPage, searchValue);
                                        }}
                                    />
                                </div>
                            ) : null
                        }
                    >
                        <TableHeader>
                            <TableColumn>Title</TableColumn>
                            <TableColumn>Domain</TableColumn>
                            <TableColumn>Last updated by</TableColumn>
                            <TableColumn>Last updated date</TableColumn>
                            <TableColumn>Preview</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {pages?.map((page) => (
                                <TableRow key={page._id} onClick={() => navigate(`/orgs/${selectedOrganization?._id}/assets/pages/individual-page/${page._id}`)} style={{ cursor: 'pointer' }}>
                                    <TableCell>{page.title}</TableCell>
                                    <TableCell>{page.domain}</TableCell>
                                    <TableCell>{page.updated_by}</TableCell>
                                    <TableCell>{page.updated_date?.split('T')[0]}</TableCell>
                                    <TableCell>
                                        <Button color="primary" onClick={(e) => {
                                            e.stopPropagation(); // Prevent TableRow onClick from being called
                                            handlePreviewClick(page.content);
                                        }}>
                                            Preview
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </div>
            <br />
            <Modal isOpen={isPreviewModalOpen} onClose={() => setIsPreviewModalOpen(false)} placement="center">
                <ModalContent>
                    <ModalHeader>
                        <h3>Page Preview</h3>
                    </ModalHeader>
                    <ModalBody>
                        {iframeLoading && (
                            <div className="flex w-full justify-center">
                                <Spinner />
                            </div>
                        )}
                        <iframe
                            srcDoc={previewHtml}
                            style={{ width: '100%', height: '400px', border: 'none', display: iframeLoading ? 'none' : 'block' }}
                            onLoad={() => setIframeLoading(false)}
                        ></iframe>
                    </ModalBody>
                    <ModalFooter>
                        <Button auto flat onClick={() => setIsPreviewModalOpen(false)}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}