import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NextUIProvider } from "@nextui-org/system";
import { RegistrationProvider } from "./contexts/RegistrationContext";
import StartPoint from "./pages/registration/StartPoint";
import { UserDetailsGuard } from './guards/registration/UserDetailsGuard';
import { BillingDetailsGuard } from './guards/registration/BillingDetailsGuard';
import { OverviewGuard } from './guards/registration/OverviewGuard';
import DashboardGuard from './guards/DashboardGuard';
import { AcceptInviteGuard } from './guards/registration/AcceptInviteGuard';
import LoginGuard from './guards/LoginGuard';
import EmailValidation from './pages/email/EmailValidation';
import TrackClickAndRedirect from './pages/TrackClickAndRedirect';
import Landing from './pages/Landing';
import { useEffect, useState } from 'react';

function App() {
  const [title, setTitle] = useState('withcold | Dashboard');

  const updateTitle = (newTitle) => {
    setTitle(newTitle);
    document.title = newTitle;
  }

  return (
    <NextUIProvider>
      <RegistrationProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/track" element={<TrackClickAndRedirect />} />
            <Route path="/validate/:organization_id/:email_address" element={<EmailValidation />} />
            <Route path="/register/start-point" element={<StartPoint />} />
            <Route path="/register/user-details" element={<UserDetailsGuard />} />
            <Route path="/register/billing-details" element={<BillingDetailsGuard />} />
            <Route path="/register/overview" element={<OverviewGuard />} />
            <Route path="/orgs/:orgId/dashboard" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/organization-settings/team" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/organization-settings/billing" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/profile-settings/invites" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/templates/create-template" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/templates/individual-template/:templateId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/templates" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/recipients" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/groups" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/campaigns/create-campaign" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/campaigns/campaign/:campaignId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/campaigns" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/messages/:recipientId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/phone-numbers" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/automations/request-automation" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/automations/automation/:automationId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/sms/automations" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/templates/create-template" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/templates/individual-template/:templateId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/templates" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/email-addresses" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/recipients" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/groups" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/campaigns/create-campaign" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/campaigns/campaign/:campaignId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/campaigns" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/automations/request-automation" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/automations/automation/:automationId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/automations" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/domains" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/email/inbox" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/assets/landing-pages" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/assets/create-page" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/orgs/:orgId/assets/pages/individual-page/:pageId" element={<DashboardGuard updateTitle={updateTitle} />} />
            <Route path="/accept-invite/:userId/:organizationId" element={<AcceptInviteGuard />} />
            <Route path="/login" element={<LoginGuard />} />
          </Routes>
        </Router>
      </RegistrationProvider>
    </NextUIProvider>
  )
}

export default App;