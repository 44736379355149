import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../contexts/RegistrationContext";
import Overview from "../../pages/registration/Overview";

/**
 * OverviewGuard is a higher-order component that wraps the Overview
 * component with additional checks and behaviors. It ensures that all necessary
 * registration information, including personal details and payment information, is
 * provided before the user can access the overview page. If any required information
 * is missing, the user is redirected to the billing-details page to complete their
 * registration. Additionally, it sets up a navigation guard to prevent data loss
 * when the page is reloaded or closed.
 *
 * @returns {React.ReactElement} The Overview component wrapped with a guard that
 * checks for complete registration data, or redirects to the billing-details page
 * if any data is missing.
 */
export const OverviewGuard = () => {
    const navigate = useNavigate();
    const { registrationData } = useRegistration();

    useEffect(() => {
        // Define the fields required for complete registration
        const requiredFields = [
            'first_name', 'last_name', 'email', 'password', 'organization_name',
            'payment_method_id', 'country', 'address_line_1', 'city', 'postal_code',
            'state_or_region'
        ];

        // Check if all required registration fields are present
        const isRegistrationDataComplete = requiredFields.every(field => registrationData[field]);

        // Redirect to the billing-details page if any required field is missing
        if (!isRegistrationDataComplete) {
            navigate('/register/billing-details');
            return; // Early return to prevent further execution and event listener attachment
        }

        // Handler to prevent data loss on page navigation or closure
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // Chrome requires returnValue to be set for the prompt to appear
        };

        // Attach the beforeunload event listener to prevent data loss
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove the event listener when the component unmounts
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [navigate, registrationData]); // Dependencies for useEffect to control re-execution

    // Render the Overview component if all registration data is present
    return <Overview />;
};