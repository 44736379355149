// frontend/src/services/billingService.js

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Validates a Stripe payment method by ID.
 * 
 * @param {string} paymentMethodId The payment method ID to validate.
 * @returns {Promise<Object>} A promise that resolves to the validation result.
 */
async function validatePaymentMethod(paymentMethodId) {
    const response = await fetch(`${API_URL}/billing/validate-payment-method/${encodeURIComponent(paymentMethodId)}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves all payment methods attached to a customer by customer ID.
 * 
 * @param {string} customerId The customer ID to retrieve payment methods for.
 * @returns {Promise<Array>} A promise that resolves to the list of payment methods.
 */
async function getCustomerPaymentMethods(customerId) {
    const response = await fetch(`${API_URL}/billing/customer/${encodeURIComponent(customerId)}/payment-methods`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves all invoices for a customer by customer ID.
 * 
 * @param {string} customerId The customer ID to retrieve invoices for.
 * @returns {Promise<Array>} A promise that resolves to the list of invoices.
 */
async function getCustomerInvoices(customerId, organizationId) {
    const response = await fetch(`${API_URL}/billing/customer/${encodeURIComponent(customerId)}/invoices?organization_id=${organizationId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Attaches a payment method to a customer.
 * 
 * @param {string} customerId The customer ID to which the payment method will be attached.
 * @param {string} paymentMethodId The payment method ID that will be attached to the customer.
 * @returns {Promise<Object>} A promise that resolves to the result of the attachment operation.
 */
async function attachPaymentMethodToCustomer(customerId, paymentMethodId) {
    const response = await fetch(`${API_URL}/billing/customer/attach-payment-method`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        },
        body: JSON.stringify({ customer_id: customerId, payment_method_id: paymentMethodId })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

/**
 * Retrieves a billing entry for a given organization by organization ID.
 * 
 * @param {string} organizationId The organization ID to retrieve the billing entry for.
 * @returns {Promise<Object>} A promise that resolves to the billing entry.
 */
async function getBillingEntry(organizationId) {
    const response = await fetch(`${API_URL}/billing/billing/${encodeURIComponent(organizationId)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            // Include other headers as required, for example, authorization headers
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

export { validatePaymentMethod, getCustomerPaymentMethods, getCustomerInvoices, attachPaymentMethodToCustomer, getBillingEntry };