// src/services/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDk583MtwzilBus5AbuZnMh1iXs8YjLBPM",
    authDomain: "messaging-63c65.firebaseapp.com",
    projectId: "messaging-63c65",
    storageBucket: "messaging-63c65.appspot.com",
    messagingSenderId: "248127938793",
    appId: "1:248127938793:web:b1d1207a5a420c53301078",
    measurementId: "G-LSS1MMJ8X3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);