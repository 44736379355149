import { Button, Card, CardBody, CardHeader, Code, Input, Select, SelectItem } from "@nextui-org/react";
import { useState } from "react";
import { CardTitle, Col, Form, Row } from "react-bootstrap";
import {
    CardElement, useElements, useStripe,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { validatePaymentMethod } from "../../services/billingService";
import { useRegistration } from "../../contexts/RegistrationContext";
import { getData } from 'country-list';

export default function BillingDetails() {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [nameOnCard, setNameOnCard] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [stateOrRegion, setStateOrRegion] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const { registrationData, updateRegistrationData } = useRegistration();
    const [country, setCountry] = useState('US');
    const countries = getData();

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        // Check if all fields are populated
        if (!nameOnCard || !addressLine1 || !city || !postalCode || !stateOrRegion) {
            setError('Please fill in all required fields.');
            setLoading(false);
            return;
        }

        // Create a payment method
        const cardElement = elements.getElement(CardElement);
        const { paymentMethod, error: stripeError } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: nameOnCard,
                address: {
                    line1: addressLine1,
                    ...(addressLine2 !== '' && { line2: addressLine2 }),
                    city: city,
                    postal_code: postalCode,
                    state: stateOrRegion,
                },
            },
        });

        if (stripeError) {
            setError(stripeError.message);
            setLoading(false);
            return;
        }

        // Validate the payment method
        try {
            const validationResponse = await validatePaymentMethod(paymentMethod.id);
            if (!validationResponse.valid) {
                setError('Payment method is invalid.');
                setLoading(false);
                return;
            }
        } catch (error) {
            setError(error.message);
            setLoading(false);
            return;
        }

        // Update the registration object with the fields
        updateRegistrationData({
            payment_method_id: paymentMethod.id,
            address_line_1: addressLine1,
            ...(addressLine2 !== '' && { address_line_2: addressLine2 }),
            city: city,
            postal_code: postalCode,
            country: country,
            state_or_region: stateOrRegion,
        })

        // Navigate to the /register/overview page
        navigate('/register/overview');
        setLoading(false);
    };

    return (
        <Card style={{ width: '90%', maxWidth: '500px', margin: '50px auto', padding: '10px' }}>
            <CardHeader>
                <CardTitle>Billing Details</CardTitle>
            </CardHeader>
            <p
                style={{
                    marginLeft: '15px',
                    marginRight: '15px',
                }}
            >Add your credit card details below. This card will be saved to your account and can be removed at any time.</p>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formCardDetails">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '10px' }}>Card Details</Form.Label>
                        {/* Stripe component goes here */}
                        <div style={{
                            border: '1px solid #dee2e6',
                            borderRadius: '10px',
                            padding: '15px',
                            marginBottom: '10px',
                        }}>
                            <CardElement options={cardStyle} id="card-element" />
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formNameOnCard"
                        style={{ marginBottom: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="bordered"
                            placeholder="Name on Card"
                            value={nameOnCard}
                            onChange={(e) => setNameOnCard(e.target.value)}
                        />
                    </Form.Group>
                    <b>Billing Address</b>
                    <Form.Group controlId="formCountry">
                        <Select
                            items={countries}
                            value={country}
                            variant="bordered"
                            label="Country"
                            onChange={(e) => setCountry(e.target.value)}
                        >
                            {countries.map((country) => (
                                <SelectItem key={country.code} value={country.code}>
                                    {country.name}
                                </SelectItem>
                            ))}
                        </Select>
                    </Form.Group>
                    <Form.Group
                        controlId="formAddress1"
                        style={{ marginTop: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="bordered"
                            placeholder="Address line 1"
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="formAddress2"
                        style={{ marginTop: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="bordered"
                            placeholder="Address line 2"
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                        />
                    </Form.Group>
                    <Row
                        style={{ marginTop: '10px' }}
                    >
                        <Col>
                            <Form.Group controlId="formCity">
                                <Input
                                    type="text"
                                    variant="bordered"
                                    placeholder="City"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formPostalCode">
                                <Input
                                    type="text"
                                    variant="bordered"
                                    placeholder="Postal code"
                                    value={postalCode}
                                    onChange={(e) => setPostalCode(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="formStateOrRegion"
                        style={{ marginTop: '10px' }}
                    >
                        <Input
                            type="text"
                            variant="bordered"
                            placeholder="State or region"
                            value={stateOrRegion}
                            onChange={(e) => setStateOrRegion(e.target.value)}
                        />
                    </Form.Group>
                    <Button
                        style={{ width: '100%', marginTop: '20px' }}
                        color="primary"
                        onClick={handleSubmit} // Pass the event object to the handleSubmit function
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Next'}
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}