import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TrackClickAndRedirect = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailId = queryParams.get('email_id');
        const url = queryParams.get('url');

        // Send the tracking data to your API endpoint
        fetch(`https://messaging-service.cypress-software-solutions.com/api/v1/email-messages/track-click?email_id=${emailId}&url=${encodeURIComponent(url)}`, {
            method: 'GET', // Assuming GET is the correct method for your endpoint
        });

        // Redirect immediately without waiting for the fetch to complete
        window.location.href = url;
    }, [location, navigate]);

    return (
        <div></div>
    );
};

export default TrackClickAndRedirect;