import { Button, Input, Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";

export const EditTitleModal = ({
    isOpen,
    onClose,
    tempTitle,
    setTempTitle,
    handleSavePageTitle
}) => (
    <Modal isOpen={isOpen} onClose={onClose} placement="top-center">
        <ModalContent>
            <ModalHeader>
                <h3>Edit Page Title</h3>
            </ModalHeader>
            <ModalBody>
                <Input
                    type="text"
                    value={tempTitle}
                    onChange={(e) => setTempTitle(e.target.value)}
                    variant="bordered"
                    style={{ width: '100%' }}
                />
                <Button
                    color="primary"
                    onClick={handleSavePageTitle}
                    style={{ marginTop: '1rem' }}
                >Save</Button>
            </ModalBody>
        </ModalContent>
    </Modal>
);