import { Button, Card, CardBody, CardHeader, Code, Input } from "@nextui-org/react";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { CardTitle, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { acceptInvitation } from "../../services/userService";

export default function AcceptInvite({ userData, organizationId }) {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
        const auth = getAuth();
        try {
            // Try to create user in Firebase
            const userCredential = await createUserWithEmailAndPassword(auth, userData.email, password);
            console.log('User created:', userCredential.user);
        } catch (creationError) {
            // If user already exists, try to log them in
            if (creationError.code === 'auth/email-already-in-use') {
                try {
                    const userCredential = await signInWithEmailAndPassword(auth, userData.email, password);
                    console.log('User logged in:', userCredential.user);
                } catch (loginError) {
                    setError(loginError.message);
                    return;
                }
            } else {
                setError(creationError.message);
                return;
            }
        }
        // Accept the invitation
        await acceptInvitation(userData._id, organizationId);
        // Navigate to the dashboard
        navigate(`/orgs/${organizationId}/dashboard`);
    };

    return (
        <Card style={{ width: '90%', maxWidth: '500px', margin: '50px auto', padding: '10px' }}>
            <CardHeader>
                <CardTitle>Accept Invite</CardTitle>
            </CardHeader>
            <CardBody>
                {error && <Code color="danger">{error}</Code>}
                <Form>
                    <Form.Group controlId="formPassword">
                        <Form.Label style={{ fontWeight: 'bold', marginTop: '5px' }}>Password</Form.Label>
                        <Input
                            placeholder="Enter password"
                            type="password"
                            variant="bordered"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Form.Group>
                    <Button
                        style={{ width: '100%', marginTop: '20px' }}
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Form>
            </CardBody>
        </Card>
    )
}