import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRegistration } from "../../contexts/RegistrationContext";
import UserDetails from "../../pages/registration/UserDetails";

/**
 * UserDetailsGuard is a higher-order component that wraps the UserDetails
 * component with additional checks and behaviors. It verifies the presence of
 * essential registration information, such as the user's email and organization name,
 * before granting access to the UserDetails page. If this information is absent,
 * the user is redirected to the start-point page to complete the necessary registration steps.
 * This guard also implements a navigation safeguard to prevent data loss during page
 * reloads or closures.
 *
 * @returns {React.ReactElement} The UserDetails component with a guard that ensures
 * the presence of minimum required registration data, or redirects to the start-point
 * page if the data is missing.
 */
export const UserDetailsGuard = () => {
    const navigate = useNavigate();
    const { registrationData } = useRegistration();

    useEffect(() => {
        // Validate the presence of minimum required registration fields
        if (!registrationData.email || !registrationData.organization_name) {
            // Redirect to the start-point page if essential fields are absent
            navigate('/register/start-point');
            // Early return to halt execution and prevent event listener attachment
            return;
        }

        // Function to handle potential data loss on page navigation or closure
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            // Set returnValue for compatibility with Chrome's prompt display requirements
            event.returnValue = '';
        };

        // Add the beforeunload event listener to safeguard against data loss
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Define a cleanup function to detach the event listener upon component unmount
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [navigate, registrationData]); // useEffect dependencies to manage re-execution

    // Conditionally render the UserDetails component based on data availability
    return <UserDetails />;
};